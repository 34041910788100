<template>
  <price-card v-model="data" :configs="localConfigs"/>
</template>

<script>
import cardMixins from "../mixins";
import discountMixins from "@/mixins/discount"

export default {
  mixins: [cardMixins, discountMixins],
  methods: {
    setPrice(configs) {
      const target = configs.find((config) => config.id === "price");
      if (!target) return;
      target.label = `單價`;
      // target.value = this.getDiscountPrice({
      //   discount_config: this.data.discount_config,
      //   price: this.data.price,
      //   quantity: this.data.shipping - this.data.count
      // })
      target.value = Number(this.data.order_item_price).toFixed(0)
      target.valueSuffix = this.priceTextFunc({
        unit: this.calculate_unit,
        showPrice: false
      });
    },
    setReturnCount(configs) {
      const target = configs.find((config) => config.id === "return_count");
      if (!target) return;

      target.value = this.$helper.reverseShippingCount(this.value.count, this.shipping_unit)
      target.valueSuffix = this.shipping_unit
    },
    calculateTotal(target, config) {
      if(target.value == null) {
        let total = this.count小計(config)
        target.value = isNaN(total) ? null : total;
      }
      this.total = target.value
    },
    count小計(config) {
      const {shipping_unit, shipping, price, per_shipping_count, amount} = config
      // console.log('config',config)
      const return_count = this.$helper.transformShippingCount({count: this.value.return_count, unit: shipping_unit})
      // console.log('return_count', return_count)
      const quantity = (+shipping - return_count)
      // const price = this.getDiscountPrice({
      //   discount_config: config.discount_config,
      //   price: config.price,
      //   quantity
      // })
      // console.log('price', price, 'quantity',quantity)
      // console.log('amount', amount)
      // console.log('小記', amount - ( quantity * price * per_shipping_count))
      return amount - ( quantity * price * per_shipping_count)
    },
  },
};
</script>